import React from "react";
import "../../../../../assets/css/custom.css";
import {
  Container,
  Col,
} from "react-bootstrap";
import { FaInstagramSquare, FaFacebookF, FaTwitterSquare, FaTripadvisor, FaYelp, } from "react-icons/fa";
import { ImYoutube2 } from "react-icons/im";
import Typography from '@mui/material/Typography';

const facebookLink = process.env.REACT_APP_LINK_FACEBOOK;
const instagramLink = process.env.REACT_APP_LINK_INSTAGRAM;
const twitterLink = process.env.REACT_APP_LINK_TWITTER;
const yelpLink = process.env.REACT_APP_LINK_YELP;
const tripadvisorLink = process.env.REACT_APP_LINK_TRIPADVISOR;

export const SocialConnect = () => {

  const socialLinks = [
    { href: facebookLink, icon: <FaFacebookF />, alt: "Follow us on Facebook" },
    { href: instagramLink, icon: <FaInstagramSquare />, alt: "Follow us on Instagram" },
    { href: twitterLink, icon: <FaTwitterSquare />, alt: "Follow us on Twitter" },
    { href: yelpLink, icon: <FaYelp />, alt: "Make a reservation on Yelp" },
    { href: tripadvisorLink, icon: <FaTripadvisor />, alt: "Write a review on Tripadvisor" },
  ];

  return (
    <div className="social-connect">
      <Container className="social-icons">
        <Col xs={12} className="social-title">
          <Typography variant="h4" gutterBottom component="div">
            Social Connect
          </Typography>
        </Col>
        <Col xs={12} className="social-icons icon">
          <ul className="social-tags">
            {socialLinks.map((link, index) => (
              link.href ? (
                <li key={index}>
                  <a href={link.href} target="_blank" alt={link.alt} aria-hidden="true">
                    <i className={`fab ${link.icon.props.className}`}>{link.icon}</i>
                  </a>
                </li>
              ) : null
            ))}
          </ul>
        </Col>
      </Container>
    </div>
  );
};