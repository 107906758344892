const config = {

    // Landing Page
    landingcontent: {
        description: "Welcome to Roma Catering, your go-to destination in Aliquippa, PA, for an authentic Italian culinary experience. Our latest news brings you insights into the heartwarming experiences shared by our customers. From the love for our diverse menu to the appreciation of fresh flavors and wonderful soups, discover why Roma Catering stands out in providing outstanding food and quick service. Join us on a journey of true Italian quality, where everything is made to order, ensuring freshness and a delightful dining experience.",
        keywords: "Roma Catering, Aliquippa, PA, Italian Cuisine, Fresh Flavors, Wonderful Soups, Fine Dining, Quick Service, Homemade Pasta, Authentic Italian Food, Customer Reviews, Convenience in Dining, Culinary Experience, Delicious Meals, Love for Food, Menu Variety, Quality Dining, Dining Convenience, Homemade Pasta, True Italian Quality, Outstanding Food, Culinary Delights, Quick and Delicious, Catering Services, Pasta Perfection, Gourmet Experience, Flavorful Dishes, Tasty Italian Treats, Gastronomic Pleasures, Food Enthusiasts, Savory Delights, Taste Sensations, Authentic Flavors, Delicious Memories, Food Appreciation, Tantalizing Palates, Gourmet Choices, Italian Delicacies, Culinary Craftsmanship, Tasty Creations, Savor the Moment, Delightful Dining, Exquisite Cuisine, Gourmet Satisfaction, Culinary Excellence, Tasty Creations, Appetizing Fare, Gastronomic Adventures, Satisfying Palates, Culinary Discoveries.",
    },

    // About Us Page
    aboutcontent: {
        title: "Love the Authenticity of Italian Cuisine",
        subtitle: "Roma Catering, located in Aliquippa, PA serves authentic, homemade Italian food.  We offer catering and retail take-out on Thursdays and Sundays.",
        description: "Our love and passion for healthy eating and cooking has inspired us to create original recipes for you to enjoy and experience a little of Italy in your own home. Our specialties blend traditional with contemporary ingredients to create delicious, authentic Italian dishes with aromas and memories that stay with us.",
        keywords: "Roma Catering, Aliquippa, Pennsylvania, 30 Years Celebration, Italian Culture, Love and Passion, Family Memories, Mamma's Kitchen, Rustic Dishes, Tried and Tested Recipes, Italian Passion, Simplicity in Cooking, Healthy Eating, Original Recipes, Italian Specialties, Aromas and Memories, Fresh Ingredients, No Preservatives, Care and Passion in Cooking, Mediterranean Diet, Lifestyle and Health, Fresh Homemade Pastas, Gnocchi Varieties, Glorious World of Pasta, Fresh Produce, Finest Cheeses, Purest Olive Oils, Handed Down Recipes, Pasta Aficionado, Simple Fresh Cuisine, Meatball Specialties, Stuffed Pork Chops, Roast Beef, Turkey and Chicken Dishes, Big Family of Customers, Working Mothers, Senior Favorites, Kid-Friendly Meals, La Vita Bella, Complete Meals, Healthy Living, Simple Italian Cuisine, Whole Family Enjoyment, Diva DeSantis, Mangia Bene, Vivi Bene, Culinary Experience, Tradition and Pride, Mediterranean Lifestyle, Balanced Elements, Natural and Unprocessed Foods, Good Health Wishes",
        message: 'Allow Roma Catering to infuse "la vita bella" (the beautiful life) into your home with wholesome, uncomplicated meals designed for the enjoyment of your entire family. Experience the joy of savoring complete and healthy dishes that will undoubtedly please your palate. Wishing you a life filled with longevity and good health. Mangia Bene, Vivi Bene (Eat well, Live well). ~ Diva DeSantis',
    },
    aboutbusiness: {
        paragraph1:
            'Roma Catering, based in Aliquippa, PA, stands out as a culinary gem, providing a delightful culinary experience rooted in authenticity and quality. The business takes pride in its commitment to delivering exceptional Italian cuisine, evident in the glowing testimonials featured on their blog. Customers express admiration for the freshness and flavor of the food, with a particular highlight on the wonderful soups that leave a lasting impression. Roma Catering has successfully created a welcoming atmosphere, where friendly service and a wide variety of dishes contribute to a truly enjoyable dining experience.',
        paragraph2:
            "The testimonials on Roma Catering's blog underscore the business's commitment to excellence. Customers consistently praise the outstanding quality of the food and quick service, emphasizing the convenience of enjoying fine Italian cuisine without the hassle of cooking. The testimonials also applaud the freshness of the ingredients and the made-to-order approach, highlighting the homemade pasta as a standout feature. With a focus on customer satisfaction, Roma Catering has built a reputation for delivering true Italian quality in both its products and service. The testimonials also provide constructive feedback, suggesting a desire for more menu options, such as additional soups or Alfredo sauces. These testimonials not only showcase the success of Roma Catering but also reflect the business's ongoing dedication to meeting customer preferences and maintaining a high standard of culinary excellence.",
    },
    littleaboutus: {
        paragraph1:
            'Love, passion for food, family and life itself describes Italian culture. We all have fond memories of our mamma in the kitchen. Every dish she prepared was made with 2 main ingredients, love and pride, served with a side of tradition. At Roma Catering, we are trying to recreate what Italians are most proud of – fantastic, rustic dishes, using recipes that have been tried, tested and loved for generations. Our food spells out Italian passion for simplicity with every element in balance.',
        paragraph2:
            'Our love and passion for healthy eating and cooking has inspired us to create original recipes for you to enjoy and experience a little of Italy in your own home. Our specialties blend traditional with contemporary ingredients to create delicious, authentic Italian dishes with aromas and memories that stay with us. Simple, fresh ingredients with no preservatives, prepared and cooked with care and passion.',
        paragraph3:
            'Research has proven that we get the most energy from natural, unprocessed foods. Come let Roma Catering share our vision and belief that food should make you healthy and happy. We pride ourselves in serving the best Italian and Mediterranean food. The Mediterranean Diet has been studied and noted by scores of leading scientists as one of the healthiest in the world. It is a lifestyle that includes foods made with the freshest ingredients, activities, meals with friends and family, and wine in moderation with meals. From fresh homemade pastas and gnocchi, from papardelle to penne, and from primavera to pesto, we celebrate the glorious world of pasta. Fresh produce and tomatoes, the finest of pecorino, parmigiano and ricotta cheeses, the purest of olive oils are used to preserve the secrets of handed down recipes. Whether you are a pasta aficionado or a beginner, you will enjoy our simple fresh, natural Italian cuisine. Our meatballs, stuffed pork chops, roast beef, turkey and chicken specialties are loved by our big family of many customers, including working mothers, seniors, and the very young.',
    },

    // Contact Page
    contactcontent: {
        title: "Give Us A Call! 1-724-788-4800 Or Come By! 2132 Sheffield Plaza, Aliquippa, Pennsylvania",
        description: "Indulge in the unparalleled culinary experience offered by Roma Catering, where a commitment to using only the freshest, high-quality ingredients meets the artistry of our exclusive recipes. Immerse yourself in the authentic flavors of Italy as our in-house, handmade pastas, crafted from scratch with precision using state-of-the-art machines imported from Italy, promise a genuine taste of freshness in every bite. Let us handle the kitchen duties during your busy fall schedule, offering the area's finest and exclusive selection of authentic homemade Italian foods that are available anytime. Roma Catering is not just a meal; it's a celebration of exquisite taste and culinary mastery.",
        keywords: "Roma Catering, Fresh Ingredients, Homemade Italian Foods, Culinary Excellence, Exclusive Selection, Authentic Flavors, Handmade Pastas, Scratch Cooking, Imported Italian Machines, Genuine Freshness, Exquisite Taste, Artisanal Recipes, Finest Foods, Busy Fall Schedule, Culinary Mastery, Exclusive Catering, Gourmet Cuisine, Italian Culinary Experience, Timeless Recipes, Anytime Availability",
    },

    // Photo Gallery Page
    gallerycontent: {
        description: "Experience the culinary magic with Roma Catering! Indulge in our fresh ingredients, savor the perfection of homemade Italian foods, and elevate your taste buds with culinary excellence. Enjoy our exclusive selection, authentic flavors, and the artistry of handmade pastas crafted from scratch. With imported Italian machines ensuring genuine freshness, every bite is an exquisite taste of our commitment to perfection. Discover the essence of artisanal recipes, the finest foods, and the convenience of catering to your busy fall schedule. Trust us for culinary mastery, exclusive catering, and a gourmet Italian culinary experience. Explore the timeless recipes and relish our offerings with anytime availability. Your extraordinary dining experience awaits!",
        keywords: "Italian cuisine, international influence, Frisco, Colorado, culinary journey, traditions, flavors, craftsmanship, housemade bread, pasta, desserts, passion, dedication, gastronomic adventure, borders, freshly baked bread, handcrafted desserts, authentic, global twist, Frisco dining, love, taste, world, plate, Book, private event, Bagalis, Family reunions, Birthday gatherings, business luncheons, small group, brunch, happy hour, appropriately priced, mushroom flatbread, favorite place, Main Street, pizzas, half off, Begali, tomato basil soup, Burrata appetizer, service, prompt, attentive, busy, breakfast, Bread & Salt, menu, Frisco Omelette, Chicken Pesto sandwich, Bolognese, worth every penny, vegetarian options, bland, meat eating friend, bad night, local Italian restaurant, Thanksgiving dinner, ice skating, late night snack, waitress, displeased, appetizers, customer service, awful, food, decent, noteworthy, Thanksgiving Special, fixed price, gravy, curdled, spoiled milk, options, traveling, wouldn't go back",
        message: "Elevate your dining experience with Roma Catering! Indulge in our fresh ingredients, homemade Italian foods, and culinary excellence.",
    },

    // Menu Page
    ordercontent: {
        title: "Eat Well, Live Well! Buon Appetito!",
        description: "From exclusive selections to genuine freshness, enjoy our exquisite taste and anytime availability. Discover the essence of artisanal recipes, the finest foods, and the convenience of catering to your busy fall schedule. Trust us for culinary mastery, exclusive catering, and a gourmet Italian culinary experience.",
        message: "There is only one Italy, and there's only one real Italian food. Only here can you find five cooks blending tradition, passion, and expertise to craft the authentic flavors that define the heart and soul of Italian cuisine.",
    },
};

export default config;